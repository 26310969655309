dmx.animate = function(node, effect, duration, delay) {
    dmx.animate.removeAllEffects(node);

    requestAnimationFrame(function() {
        dmx.animate.removeAllEffects(node);

        if (delay) {
            node.style.setProperty('animation-delay', delay + 'ms');
        }

        if (duration) {
            node.style.setProperty('animation-duration', duration + 'ms');
        }

        node.classList.add('animated', effect);
    });
};

dmx.animate.removeAllEffects = function(node) {
    node.style.removeProperty('animation-delay');
    node.style.removeProperty('animation-duration');
    node.classList.remove('animated');
    dmx.array(node.classList).forEach(function(className) {
        if (dmx.animate.effects.indexOf(className) >= 0) {
            node.classList.remove(className);
        }
    }, this);
};

dmx.animate.effects = ['bounce', 'flash', 'pulse', 'rubberBand', 'shake', 'headShake', 'swing', 'tada', 'wobble', 'jello',
'bounceIn', 'bounceInDown', 'bounceInLeft', 'bounceInRight', 'bounceInUp', 'bounceOut', 'bounceOutDown', 'bounceOutLeft',
'bounceOutRight', 'bounceOutUp', 'fadeIn', 'fadeInDown', 'fadeInDownBig', 'fadeInLeft', 'fadeInLeftBig', 'fadeInRight',
'fadeInRightBig', 'fadeInUp', 'fadeInUpBig', 'fadeOut', 'fadeOutDown', 'fadeOutDownBig', 'fadeOutLeft', 'fadeOutLeftBig',
'fadeOutRight', 'fadeOutRightBig', 'fadeOutUp', 'fadeOutUpBig', 'flipInX', 'flipInY', 'flipOutX', 'flipOutY', 'lightSpeedIn',
'lightSpeedOut', 'rotateIn', 'rotateInDownLeft', 'rotateInDownRight', 'rotateInUpLeft', 'rotateInUpRight', 'rotateOut',
'rotateOutDownLeft', 'rotateOutDownRight', 'rotateOutUpLeft', 'rotateOutUpRight', 'hinge', 'rollIn', 'rollOut', 'zoomIn',
'zoomInDown', 'zoomInLeft', 'zoomInRight', 'zoomInUp', 'zoomOut', 'zoomOutDown', 'zoomOutLeft', 'zoomOutRight', 'zoomOutUp',
'slideInDown', 'slideInLeft', 'slideInRight', 'slideInUp', 'slideOutDown', 'slideOutLeft', 'slideOutRight', 'slideOutUp'];

dmx.animate.nodes = [];

dmx.animate.watch = function(node) {
    if (dmx.animate.nodes.indexOf(node) === -1) {
        dmx.animate.nodes.push(node);
    }
};

dmx.animate.update = function() {
    if (!dmx.animate.nodes.length) return;

    var win = { x: window.pageXOffset, y: window.pageYOffset, w: window.innerWidth, h: window.innerHeight };

    dmx.animate.nodes = dmx.animate.nodes.filter(function(node) {
        if (!node.offsetParent) return true;

        var p = node.offsetParent;
        var x = node.offsetLeft;
        var y = node.offsetTop;
        var w = node.offsetWidth;
        var h = node.offsetHeight;
        var wr = w * node.animateRatio;
        var hr = h * node.animateRatio;

        while (p) {
            if (!isNaN(p.offsetLeft)) x += p.offsetLeft;
            if (!isNaN(p.offsetTop)) y += p.offsetTop;
            p = p.offsetParent;
        }

        if (y + hr <= win.y + win.h && y + h - hr >= win.y && x + wr <= win.x + win.w && x + w - wr >= win.x) {
            if (!node.animateVisible) {
                node.style.removeProperty('visibility');
                if (node.animateOutview) node.classList.remove(node.animateOutview, 'animated');
                node.classList.add(node.animateInview, 'animated');
                node.animateVisible = true;
                if (!node.animateOutview) return false;
            }
        } else {
            if (node.animateOutview && node.animateVisible !== false) {
                node.classList.remove(node.animateInview, 'animated');
                node.classList.add(node.animateOutview, 'animated');
                node.animateVisible = false;
            }
        }

        return true;
    });
};

dmx.animate.handler = (function() {
    var wait;

    return function(event) {
        if (!!wait) return;

        wait = setTimeout(function() {
            requestAnimationFrame(dmx.animate.update);
            wait = null;
        }, 250);
    };
})();

['load', 'resize', 'scroll', 'touchmove'].forEach(function(event) {
    window.addEventListener(event, dmx.animate.handler);
});
