dmx.Attribute('animate-inview', 'mounted', function(node, attr) {
    node.animateInview = attr.value;
    node.animateRatio = (parseInt(attr.argument, 10) || 100) / 100;
    node.style.setProperty('visibility', 'hidden');
    dmx.animate.watch(node);
});

dmx.Attribute('animate-outview', 'mounted', function(node, attr) {
    node.animateOutview = attr.value;
});
